<template>
<div class="container">
  <Header />
  <router-view />
  <Footer />
</div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style lang="less">
body {
  margin: 0;
  background-color: @light-200;
}

.container {
  margin: 0 auto;
  min-width: 300px;
  max-width: 1392px;
}
</style>
